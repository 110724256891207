import { useFormik } from 'formik';
import React, { forwardRef } from 'react';
import { weightVitalValidation } from '../../utils/validations';
import useLiveVitalQuery from '../../hooks/useLiveVitalQuery';
import { usePatient } from '../../context/PatientContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '../../context/ToastContext';
import { editManualWeightVital, fetchWeigthVital } from '../../HTTPS/VitalApis';
import { convertToLocalDate, convertToLocalTime, generateTimestampAndOffset, getCurrentLocalDate } from '../../utils/chartUtils';
import { useUser } from '../../context/UserContext';

function WeightEditVitalForm({ setOpen, entry }, ref) {
  const { patient } = usePatient();
  const { userDetails } = useUser();
  const queryClient = useQueryClient();
  const {
    setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage
  } = useToastContext();

  const { data: weigthVital } = useLiveVitalQuery(
    "weigthVital",
    fetchWeigthVital
  );

  const { mutate: handleAddVital, isPending } = useMutation({
    mutationFn: async ({ weight, defaultDate, defaultTime }) =>
        await editManualWeightVital(
          patient?.providerUserId,
          userDetails?.id,
          weight,
          ...generateTimestampAndOffset(defaultDate, defaultTime),
          entry.id
        ),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(["weigthVitalList", patient?.id]);
        queryClient.invalidateQueries(["weigthVital", patient?.id]);
      }, 1000);
      setOpen(false);
      setToastMessage("Vital Added Successfully");
      setOpenSuccessToast(true);
    },
    onError: () => {
      setToastMessage("Failed Adding Vital ");
      setOpenErrorSuccessToast(true);
    },
  });

  const formik = useFormik({
    initialValues: {
      weight: entry.weight,
      defaultDate: convertToLocalDate(entry.date),
      defaultTime: convertToLocalTime(entry.date),
    },
    validationSchema: weightVitalValidation(),
    onSubmit: (values) => {
      handleAddVital(values, {
        onSuccess: () => { },
        onError: () => { },
      });
    },
  });
  return (
    <>
      <form className="bg-white z-50 p-5 rounded-2xl">
        <h1 className="text-2xl mb-3">Edit data</h1>
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col space-y-1">
            <label htmlFor="dys">
              Weight {weigthVital?.unit && `(${weigthVital?.unit})`}
            </label>
            <input
              type="text"
              {...formik.getFieldProps("weight")} // name, value, onChange, onBlur
              className="border w-72 p-2 rounded-xl"
              placeholder="Weight"
            />
            {formik.touched.weight && formik.errors.weight ? (
              <span className="text-red-500 text-sm">
                {formik.errors.weight}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Date</label>
            <input
              type="date"
              {...formik.getFieldProps("defaultDate")} // name, value, onChange, onBlur
              max={getCurrentLocalDate()}
              placeholder="Date"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultDate && formik.errors.defaultDate ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultDate}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Time</label>
            <input
              type="time"
              {...formik.getFieldProps("defaultTime")} // name, value, onChange, onBlur
              placeholder="Time"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultTime && formik.errors.defaultTime ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultTime}
              </span>
            ) : null}
          </div>

          <div className="flex">
            <button
              type="submit"
              onClick={formik.handleSubmit}
              disabled={isPending}
              className="bg-[#ED7117] rounded-xl p-3 px-7 text-white"
            >
              Save
            </button>
            <button
              onClick={() => setOpen(false)}
              className="rounded-xl p-3 px-7 text-black"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default forwardRef(WeightEditVitalForm);