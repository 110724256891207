import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { ImageBaseUrl } from "../HTTPS/Api";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import { Menu } from "@mui/base/Menu";
import { MenuItem } from "@mui/base/MenuItem";
import { fetchPatientList } from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";
import MessengerService from "../servies/MessengerService";
import { useNavigate } from "react-router-dom";
import SwitchPatientModal from "../components/Modals/SwitchPatientModal";
import MainAreaWrapper from "../layout/MainAreaWrapper";

const AllPatients = () => {
  const [open, setOpen] = useState(false);
  const [selectPatientForModal, setSetselectPatientForModal] = useState(null);

  const navigate = useNavigate();
  const {
    patient,
    patientList,
    setPatient,
    setPatientList,
  } = usePatient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await fetchPatientList();
        if (list && list.length > 0) {
          const storedPatient = localStorage.getItem("selectedPatient");
          if (storedPatient) {
            setPatient(JSON.parse(storedPatient));
          } else {
            MessengerService.setSelectedPatient(list[0].id);
            setPatient(list[0]);
            localStorage.setItem("selectedPatient", JSON.stringify(list[0]));
            setPatientList(list);
          }
        }
        setPatientList(list);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };

    fetchData();
    // patientSelectHandler();
  }, [setPatient, setPatientList, patient?.id]);

  const currentIndex = patientList.findIndex((item) => item.id === patient.id);

  // Separate the current patient from the rest
  const currentPatient = currentIndex !== -1 ? patientList[currentIndex] : null;
  const otherPatients = patientList.filter((item) => item.id !== patient.id);

  const onClickViewProfile = (currentPatient) => {
    navigate("/viewPatientDetails", {
      state: { patient: currentPatient },
    });
  };

  return (
    <MainAreaWrapper>
      <main className="mt-28 lg:mt-10">
        <div className="h-full rounded-t-3xl bg-white">
          <div className="mx-auto  p-4 md:p-6 2xl:p-10">
            {currentPatient && (
              <>
                <h2 className="text-2xl sm:text-3xl font-bold text-[#1D3E7D] mb-6">
                  Selected Care Recipient
                </h2>
                {/* <p className="text-gray-600">Manage your information</p> */}

                {/* <!-- Content  --> */}
                <div
                  id={0}
                  className="shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] flex justify-between items-center p-3 sm:p-5 rounded-xl bg-[#ecf1f4] my-5"
                >
                  {/* Logo  */}
                  <div className="flex items-center gap-2 sm:gap-5">
                    <button className="flex items-center gap-4">
                      <span className="">
                        {currentPatient && (
                          <Avatar
                            key={currentPatient.id}
                            {...stringAvatar(
                              `${currentPatient.fname} ${currentPatient.lname}`
                            )}
                            className="h-10 w-10 sm:!h-16 sm:!w-16 rounded-full uppercase !text-base xs:!text-lg"
                            src={
                              currentPatient.profileImage
                                ? `${ImageBaseUrl}/${currentPatient.profileImage}`
                                : ""
                            }
                          />
                        )}
                      </span>
                    </button>

                    {/* Content  */}
                    <div className="">
                      <h2 className="text-[0.8rem] xxs:text-sm sm:text-xl font-bold">
                        {currentPatient.fname} {currentPatient.mname}{" "}
                        {currentPatient.lname}
                      </h2>
                    </div>
                  </div>
                  {/* Icons  */}

                  <div className="flex space-x-5 justify-center items-center">
                    <Dropdown>
                      <MenuButton>
                        <img
                          src="./icons/VerticalDots.svg"
                          alt="Vertical Menu"
                          className="w-5 h-5 sm:w-7 sm:h-7 lg:w-10 lg:h-10"
                        />
                      </MenuButton>
                      <Menu className="bg-white rounded-xl py-2 border">
                        <MenuItem
                          onClick={() => onClickViewProfile(currentPatient)}
                          className="cursor-pointer p-2 px-4 hover:bg-gray-100"
                        >
                          View Profile
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </div>
                </div>
              </>
            )}

            {otherPatients.length !== 0 && (
              <h2 className="text-3xl font-bold text-[#1D3E7D] mb-6">
                Others
              </h2>
            )}
            {otherPatients.map((item, i) => (
              <div
                key={i}
                id={i + 1}
                className="shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] flex justify-between items-center rounded-xl bg-[#ecf1f4] my-5"
              >
                {/* Logo  */}
                <div
                  onClick={() => {
                    setOpen(true);
                    setSetselectPatientForModal(item);
                  }}
                  className="flex items-center gap-2 sm:gap-5 p-3 sm:p-5 rounded-xl grow cursor-pointer"
                >
                  <button className="flex items-center gap-4">
                    <span className="">
                      <Avatar
                        key={item.id}
                        onClick={(e) => {
                          setOpen(true);
                          setSetselectPatientForModal(item);
                        }}
                        {...stringAvatar(`${item.fname} ${item.lname}`)}
                        className="h-10 w-10 sm:!h-16 sm:!w-16 rounded-full uppercase !text-base xs:!text-lg"
                        src={
                          item.profileImage
                            ? `${ImageBaseUrl}/${item.profileImage}`
                            : ""
                        }
                      />
                    </span>
                  </button>

                  {/* Content  */}
                  <div className="">
                    <h2 className="text-[0.8rem] xxs:text-sm sm:text-xl font-bold">
                      {item.fname} {item?.mname} {item.lname}
                    </h2>
                  </div>
                </div>
                {/* Icons  */}

                <div className="flex space-x-5 justify-center items-center">
                  <Dropdown>
                    <MenuButton>
                      <img
                        src="./icons/VerticalDots.svg"
                        alt="Vertical Menu"
                        className="w-5 h-5 sm:w-7 sm:h-7 lg:w-10 lg:h-10"
                      />
                    </MenuButton>
                    <Menu className="bg-white rounded-xl py-2 border">
                      <MenuItem
                        onClick={() => onClickViewProfile(item)}
                        className="cursor-pointer p-2 px-4 hover:bg-gray-100"
                      >
                        View Profile
                      </MenuItem>
                      <MenuItem
                        className="cursor-pointer p-2 px-4 hover:bg-gray-100"
                        onClick={(e) => {
                          setOpen(true);
                          setSetselectPatientForModal(item);
                        }}
                      >
                        Switch Recipient
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      {/* <!-- ===== Main Content End ===== --> */}
      <SwitchPatientModal
        open={open}
        setOpen={setOpen}
        patient={selectPatientForModal}
        routeToRoot={false}
      />
    </MainAreaWrapper >
  );
};
export default AllPatients;
