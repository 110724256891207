import React from "react";
import ReactApexChart from "react-apexcharts";

import {
  extractChartItemsWithMatchingEventDatesForRangeBarGraphs
} from "../../utils/chartUtils";
import { fetchGlucoseGraphData } from "../../HTTPS/GraphApis";
import { groupByDateForLineGraph, localisingTimestamp, processGroupedDataForWeightChart } from "../../utils/reuseableFunc";
import { barChartDataState, barChartLoadingState } from "../../recoil/vitalTables/barChartDataState";
import { useSetRecoilState } from "recoil";
import useVitalChartQuery from "../../hooks/useVitalChartQuery";
// import { usePatient } from "../../context/PatientContext";

function GlucoseChart({ events, lastValueDate }) {
  const numberOfDaysToFetch = 35;
  const numberOfDaysToShow = 7;
  // const { patient } = usePatient();

  const id = "glucose";
  // const chartName = patient?.fname + "_" + id

  const setChartData = useSetRecoilState(barChartDataState);
  const setChartLoading = useSetRecoilState(barChartLoadingState);

  setChartLoading(true)
  // calling chart data
  const { data: chartData, isFetched, isLoading } = useVitalChartQuery(
    "glucoseGraph",
    fetchGlucoseGraphData,
    numberOfDaysToFetch
  );

  // Check if chartData is available before rendering the chart
  if (isLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      <p>Loading...</p>
    </div>;
  }

  // Check if chartData is available before rendering the chart
  if (!isFetched || !(chartData?.length > 0)) {
    setChartData([])
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {!lastValueDate && <p>No Data Available for this Vital</p>}
      {!!lastValueDate && <p>No Recent Readings Captured for this Vital</p>}
    </div>;
  }

  const localizedChartData = localisingTimestamp(chartData)

  const groupedByDate = groupByDateForLineGraph(localizedChartData)

  const originalCopy = processGroupedDataForWeightChart(groupedByDate, 'value', numberOfDaysToShow)
  setChartData(originalCopy)
  setChartLoading(false)

  // Create a deep copy of the original data to ensure it is not referentially equal in memory
  // apexChart is modifying the object which is causing issue with recoil
  const processedData = JSON.parse(JSON.stringify(originalCopy));

  return (
    <>
      <div className="w-full mt-10 border-8 border-black/40 rounded-lg">
        <ReactApexChart
          type="bar"
          series={[
            {
              name: "glucose",
              type: "bar",
              data: processedData,
            },
            {
              name: "Activities",
              type: "scatter",
              data: extractChartItemsWithMatchingEventDatesForRangeBarGraphs(
                events,
                processedData
              ),
            },
          ]}
          width="100%"
          height="380"
          options={{
            chart: {
              id,
              parentHeightOffset: 0,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              },
              foreColor: "#FAFCFE", // color of the legend's text
              background: 'rgba(0, 0, 0, 0.4)',
            },
            colors: ["#0075FF"], // color of the bars
            dataLabels: {
              enabled: false,
            },
            markers: {
              // this is for dot on the graph; alternative of dataLabels
              size: 8,
              strokeWidth: 0,
              colors: "#ED7117ff", // adding alpha values to disable default opacity
            },
            tooltip: {
              // tooltip setting when we hover over the charts dots
              enabled: false,
            },
            legend: {
              // this is for name of the graphs at the buttom
              show: false,
            },
            fill: {
              opacity: 1, // opacity of chart bars
            },
            grid: {
              // bg grid lines of the graph
              borderColor: "rgba(236, 241, 244, 0.4)",
            },
            plotOptions: {
              bar: {
                // related with range bars
                columnWidth: "70%",
                horizontal: false,
                borderRadius: "4",
              },
            },
            xaxis: {
              type: "category",
              axisTicks: { show: false },
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              type: "numeric",
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default GlucoseChart;
