import ChartsAndTables from '../../Blocks/ChartsAndTables'
import ReportWrapper from '../ReportWrapper'
import LineGraph from './LineGraph'
import ReportTable from './ReportTable'

function LineGraphWrapper({ data, print = false }) {
  return (
    <ReportWrapper data={data} >
      {print
        ? (<div>
            <LineGraph data={data} print={print} />
            <div className='my-2'></div>
            <ReportTable data={data} print={print} />
          </div>)
        : (<ChartsAndTables
            chart={<LineGraph data={data}/>}
            table={<ReportTable data={data} />}
          />)
       }
    </ReportWrapper>
  )
}

export default LineGraphWrapper