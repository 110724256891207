import React from "react";
import { usePatient } from "../context/PatientContext";
import VitalEvent from "../components/UI/VitalEvent";
import JournalEvent from "../components/UI/JournalEvent";
import { useQuery } from "@tanstack/react-query";
import { fetchEventsList } from "../HTTPS/VitalApis";
import MainAreaWrapper from "../layout/MainAreaWrapper";

function Events() {
  const { patient } = usePatient();

  const { data: eventsList } = useQuery({
    queryKey: ["eventsList", patient?.id],
    queryFn: () =>
      fetchEventsList(
        patient?.id,
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), // seven days before date
        new Date() // present date
      ),
    enabled: !!patient?.id,
    // refetchOnWindowFocus: false,
    // staleTime: 5 * 1000,
  });

  return (
    <MainAreaWrapper>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl">
        <div className="mx-auto  p-4 md:p-6 2xl:p-10">
          <div className="mx-auto px-10">
            {/* <!-- Breadcrumb Start --> */}
            <div className="mb-6 flex flex-row items-center justify-between gap-3">
              <h2 className="text-5xl font-bold text-[#1D3E7D]">
                Activities
              </h2>
            </div>
          </div>

          <hr />

          <div className="p-5">
            {/* Cards */}
            {eventsList?.map((item, i) => (
              <div id={i} key={item.id}>
                {item?.isJournalEvent ? (
                  <JournalEvent item={item} />
                ) : (
                  <VitalEvent item={item} />
                )}
              </div>
            ))}
          </div>
        </div>
      </main>
    </MainAreaWrapper >
  );
}

export default Events;
