import React, { useState } from "react";
import moment from "moment";
import "moment-timezone";
import { ImageBaseUrl } from "../HTTPS/Api";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../hooks/convertAvatarInitital";
import { useUser } from "../context/UserContext";
import GradeIcon from "@mui/icons-material/Grade";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import { useMutation } from "@tanstack/react-query";
import { toggleEvent } from "../HTTPS/ApiService";
import { getCorrectDateTimeString, getDayFromDate, getMonthFromDate, getTimeFromDate } from "../utils/chartUtils";

const formattedTimeStamp = (time) => {
  const currentTime = moment();
  const parsedTime = moment.utc(time); // Parse time as UTC

  const userTimezone = moment.tz.guess(); // Get user's timezone

  // Convert the parsed time to the user's timezone
  parsedTime.tz(userTimezone);

  if (parsedTime.isSame(currentTime, "day")) {
    // If the timestamp is from today, display as "Now"
    return parsedTime.format("MMMM D, h:mmA");
  } else if (parsedTime.isSame(currentTime, "year")) {
    // If the timestamp is from this year, display the time
    return parsedTime.format("MMMM D, h:mmA");
  } else {
    // If the timestamp is from a different year, display the full date and time
    return parsedTime.format("MMMM D, YYYY, h:mmA");
  }
};

const JournalEvents = ({
  events = [],
  onDelete,
  setEditActivityOpen,
  setActivityToBeEdited,
  fetchEvents,
  categories,
}) => {
  // Function to toggle the 'clamp' state

  const { user, userDetails } = useUser();

  const [clamp, setClamp] = useState(false);
  const toggleClamp = () => {
    setClamp(!clamp);
  };

  // Delete note Function
  const handleDeleteClick = async (eventId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete the Activity?"
    );
    // Check if the user clicked OK
    if (userConfirmed) {
      // Call the onDelete function passed from the parent component
      await onDelete(eventId);
    }
  };

  const { mutate } = useMutation({
    mutationFn: async ({ id, isImp }) => await toggleEvent(id, !isImp),
    onSuccess: () => {
      fetchEvents();
    },
    onError: () => {},
  });

  return (
    <div className="w-full">
      {events.map((item, index) => {
        // If the js object has ActiveEvent as Unactive
        // const isUnActiveEvent = item.ActiveEvent === "Unactive";

        const id = item?.id;
        const isImp = item?.isImp;
        return (
          <div id={index}>
            <div className="flex space-x-2 sm:space-x-4 w-full h-full bg-[#001640] mt-2 rounded-xl">
              <div className="bg-[#0492C2] flex flex-col p-2 rounded-xl justify-center items-center min-w-[5rem]">
                <span className="text-white text-xl sm:text-2xl font-bold">
                  {getDayFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
                </span>
                <span className="text-white text-sm">
                  {getMonthFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
                </span>
                <span className="text-white text-[13px]">
                  {getTimeFromDate(getCorrectDateTimeString(item.evDate, item.evTime))}
                </span>
              </div>

              <div className="flex flex-wrap sm:flex-nowrap justify-between items-center w-full">
                {/* User Event Text And Image  */}
                <div className="py-3">
                  <div className="flex items-center space-x-2 pt-3 pb-2">
                    <span className="min-w-max">
                      <Avatar
                        src={
                          ImageBaseUrl
                            ? `${ImageBaseUrl}/${item?.profileImage}`
                            : ""
                        }
                        {...stringAvatar(item?.user)}
                        className="w-10 h-10 rounded-full uppercase !text-base"
                      />
                    </span>

                    <div className="">
                      {/* Event Category  */}
                      <span className="font-bold text-white sm:text-base">
                        {categories?.find(i => i?.id === item?.type)?.text}
                      </span>

                      {/* Event Desc  */}
                      <span
                        className={`text-start text-[13px] sm:text-base line-clamp-2 text-white max-w-[5rem] xs:max-w-[10rem] sm:max-w-xl md:max-w-full after:text-red-500 ${
                          clamp ? "line-clamp-none" : ""
                        }`}
                        onClick={toggleClamp}
                      >
                        {item.text}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Delete Edit BUtton  */}
                <div className="flex pr-2 space-x-2 sm:px-3 sm:space-x-3 mb-3 xs:mb-0">
                  <button
                    onClick={() => {
                      setActivityToBeEdited(item)
                      setEditActivityOpen(true);
                    }}
                    disabled={
                      userDetails?.id !== item.userId &&
                      user?.role !== "Kare Admin"
                    }
                    className={` ${
                      userDetails?.id !== item.userId &&
                      user?.role !== "Kare Admin"
                        ? "opacity-30"
                        : ""
                    }`}
                  >
                    {/* Edit Button */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 sm:w-6 sm:h-6"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11 2H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6c5 0 7-2 7-7v-2"
                        stroke="#f9f9f9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M16.04 3.02 8.16 10.9c-.3.3-.6.89-.66 1.24l-.43 3.01c-.16 1.09.61 1.85 1.7 1.7l3.01-.43c.42-.06 1.01-.36 1.24-.66l7.88-7.88c1.36-1.36 2-2.94 0-4.94-2-2-3.58-1.36-4.94 0Z"
                        stroke="#f9f9f9"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M14.91 4.15a7.144 7.144 0 0 0 4.94 4.94"
                        stroke="#f9f9f9"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <button
                    onClick={() => handleDeleteClick(item.id)}
                    disabled={
                      userDetails?.id !== item.userId &&
                      user?.role !== "Kare Admin"
                    }
                    className={` ${
                      userDetails?.id !== item.userId &&
                      user?.role !== "Kare Admin"
                        ? "opacity-30"
                        : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 sm:w-6 sm:h-6"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M8.81 2 5.19 5.63M15.19 2l3.62 3.63"
                        stroke="#ff0000"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M2 7.85c0-1.85.99-2 2.22-2h15.56c1.23 0 2.22.15 2.22 2 0 2.15-.99 2-2.22 2H4.22C2.99 9.85 2 10 2 7.85Z"
                        stroke="#ff0000"
                        strokeWidth="1.5"
                      ></path>
                      <path
                        d="M9.76 14v3.55M14.36 14v3.55M3.5 10l1.41 8.64C5.23 20.58 6 22 8.86 22h6.03c3.11 0 3.57-1.36 3.93-3.24L20.5 10"
                        stroke="#ff0000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            {/* Date */}
            <div className="flex items-center space-x-2 mb-2 ">
              <span
                onClick={() => mutate({ id, isImp })}
                className="text-gray-500 flex justify-center items-center space-x-2 cursor-pointer"
              >
                {isImp ? (
                  <GradeIcon className="text-[#ED7117]" />
                ) : (
                  <GradeOutlinedIcon className="text-[#ED7117]" />
                )}
              </span>
              <span className="text-black text-[12px]">{item?.user}</span>
              <span className="text-[12px] text-gray-500">
                {formattedTimeStamp(item?.createdDate)}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JournalEvents;
