import { atom } from 'recoil';

export const rangeBarChartDataState = atom({
  key: 'rangeBarChartDataState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const rangeBarChartLodingState = atom({
  key: 'rangeBarChartLodingState', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
})