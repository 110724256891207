import React, { useState } from "react";
import VitalScoreDisplayCard from "../VitalScoreDisplayCard";
import {
  formatDate,
  formatTime,
  roundToOneDecimal,
} from "../../utils/chartUtils";
import AddEventPlusIconBtn from "../UI/AddEventPlusIconBtn";
import TempVitalForm from "../Forms/TempVitalForm";
import ReuseableModel from "../Modals/ReuseableModel";
import { useUser } from "../../context/UserContext";

function TempAddAndDisplayLiveVital({ temperatureVital }) {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  return (
    <>
      <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
        <VitalScoreDisplayCard
          stats={roundToOneDecimal(temperatureVital?.value)}
        />
        <button
          id='add-vital'
          onClick={() => setOpen(true)}
          disabled={user?.role === "Viewer"}
          className={`${user?.role === "Viewer" ? "opacity-50" : ""}`}
        >
          <AddEventPlusIconBtn />
        </button>
        <p className="text-white font-semibold text-center text-sm mt-4">
          {temperatureVital?.date
            ? `Updated on ${formatDate(temperatureVital?.date)} at ${formatTime(
                temperatureVital?.date
              )}`
            : null}
        </p>
      </div>

      <ReuseableModel open={open} setOpen={setOpen}>
        <TempVitalForm temperatureVital={temperatureVital} setOpen={setOpen} />
      </ReuseableModel>
    </>
  );
}

export default TempAddAndDisplayLiveVital;
