import React, { useState, useRef, useEffect } from "react";
import MessageSendBar from "../components/UI/MessageSendBar";
import MessengerService from "../servies/MessengerService";

import "moment-timezone";
// import { CachedUserDetails } from "../utils/UserData";
import { SendingChatCard } from "../components/MessageSkeletonCard";
import { usePatient } from "../context/PatientContext";
import SenderChatCard from "../components/UI/SenderChatCard";
import ReceiverChatCard from "../components/UI/ReceiverChatCard";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { useUser } from "../context/UserContext";

const Message = () => {
  const { user, userDetails } = useUser();
  const [messages, setMessages] = useState([]);
  const [previewMessageCard, setPreviewMessageCard] = useState(null);
  const { patient } = usePatient();

  const messageRef = useRef();
  useEffect(() => {
    let msgRefreshInterval;
    if (patient?.id) {
      MessengerService.setSelectedPatient(patient?.id);
      // destruturing the array: the component was not refreshing for new messages, maybe due to the shallow comparision of the "MessengerService.messages" thus we are destructuring for a time being
      const msgRefresh = () => setMessages([...MessengerService.messages]);
      msgRefreshInterval = setInterval(msgRefresh, 500);
      // clearing the interval
    }
    return () => clearInterval(msgRefreshInterval);
  }, [patient?.id]);

  useEffect(() => {
    const scrollToBottom = async () => {
      // await MessengerService.fetchPatientFromStorage(true);
      await MessengerService.readAllGroupMessagesUpdate();
      if (messages?.length !== 0) {
        messageRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToBottom();
  }, [messages.length]);

  const messagesByMonth = {};

  // Group messages by month
  messages.forEach((msg) => {
    // Get the month from the message's time
    const messageMonth = new Date(msg.time).toLocaleString("en-US", {
      month: "short",
    });
    // Add message to the respective month array
    if (!messagesByMonth[messageMonth]) {
      messagesByMonth[messageMonth] = [];
    }
    messagesByMonth[messageMonth].push(msg);
  });

  return (
    <MainAreaWrapper>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl">
        {/* Message Bar  */}

        {/* <MessageComp /> */}
        <div className="mx-auto  p-4 md:p-6 2xl:p-10">
          <div className="mx-auto">
            {/* <!-- Breadcrumb Start --> */}
            <div className="mb-6 flex flex-row items-center justify-between gap-3">
              <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
                Broadcast
              </h2>
            </div>

            {Object.entries(messagesByMonth).map(([month, messages]) => (
              <div key={month} className="mt-6">
                <div className="flex justify-center items-center relative">
                  <div className="border bg-white border-gray-400 rounded-full items-center px-7 sm:px-20 py-0 w-min z-10">
                    {month}
                  </div>
                  <span className="w-full h-[0.1px] absolute bg-gray-500 " />
                </div>

                {messages.map((msg, i) => {
                  const members = JSON.parse(localStorage.getItem("communityMembers"));
                  const msgSender = members.find((user) => user?.userId === parseInt(msg?.id));

                  if (msgSender) {
                    msg.name = msgSender?.name;
                    msg.profileImage = msgSender?.profileImage;
                  }
                  else {
                    msg.name = 'Deleted User';
                    msg.profileImage = null;
                  }
                  return (
                    <div key={i}>
                      {parseInt(msg?.id) === userDetails?.id ? (
                        <SenderChatCard
                          text={msg?.text}
                          profileImage={msg?.profileImage}
                          name={msg?.name}
                          time={msg?.time}
                          messageRef={messageRef}
                        />
                      ) : (
                        <ReceiverChatCard
                          text={msg?.text}
                          profileImage={msg?.profileImage}
                          name={msg?.name}
                          time={msg?.time}
                          messageRef={messageRef}
                        />
                      )}
                      {/* {console.log(msg)} */}
                    </div>
                  );
                })}
              </div>
            ))}

            {/* Send */}
            {/* <SendChatCard profileImage={"../images/user/Dummy-User.jpeg"} /> */}

            {/* Receive */}
            {/* <ReceiveChatCard
                  profileImage={"../images/user/Dummy-User.jpeg"}
                /> */}

            {previewMessageCard && (
              <SendingChatCard
                text={previewMessageCard?.content}
                profileImage={previewMessageCard?.profileImage}
                name={previewMessageCard?.name}
                time={previewMessageCard?.time}
                messageRef={messageRef}
              />
            )}
          </div>
        </div>

        {user?.role !== "Viewer" && (
          <div className="mt-10 sm:mt-0 sm:hidden">
            <div className="fixed sm:sticky w-full bottom-0">
              <MessageSendBar
                isMessage={true}
                messageRef={messageRef}
                setPreviewMessageCard={setPreviewMessageCard}
              />
            </div>
          </div>
        )}

        {user?.role !== "Viewer" && (
          <div className="fixed sm:sticky w-full bottom-0 hidden sm:inline-block">
            <MessageSendBar
              isMessage={true}
              messageRef={messageRef}
              setPreviewMessageCard={setPreviewMessageCard}
            />
          </div>
        )}
      </main>
    </MainAreaWrapper>
  );
};

export default Message;
