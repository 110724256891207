import { atom } from 'recoil';

export const lineChartDataState = atom({
  key: 'lineChartDataState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)

});
export const lineChartLoadingState = atom({
  key: 'lineChartLoadingState', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
})