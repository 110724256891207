import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import {
  getCorrectDateTimeString,
  getDateFromDate,
} from "../../utils/chartUtils";
// import useToast from '../../hooks/useToast';
import { listNotesApi } from "../../HTTPS/ApiService";
import { usePatient } from "../../context/PatientContext";
import SearchBar from "../UI/SearchBar";
// import NoteBox from '../NoteBox';
import Observation from "../UI/Observation";
import ReuseableBlurModel from "../Modals/ReuseableBlurModel";
import EditObservationForm from "../Forms/EditObservationForm";

function ObservationBlock() {
  const [editObservationOpen, setEditObservationOpen] = useState(false);
  const [observationToBeEdited, setObservationToBeEdited] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterNoteType, setFilterNoteType] = useState("past");
  // const [currentlyEditedNoteId, setCurrentlyEditedNoteId] = useState(null);
  const { patient } = usePatient();
  // pending
  // const noteRef = useRef(null);

  // pending
  // const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } = useToast();

  const {
    data: notes,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Observation", patient?.id],
    queryFn: () => listNotesApi(patient?.id),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
    // Issue 0003 - More config of react query
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error occured while loading observations</p>;
  }

  if (notes?.length === 0) {
    return <p>No Observation found.</p>;
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const today = moment();

  const handleClearFilter = () => {
    setSearchTerm("");
    setFilterNoteType("all"); // Assuming "all" is the default value for the note filter
  };

  const filterByDate = (item, itemDate, filterType) => {
    const momentItemDate = moment(
      getDateFromDate(itemDate),
      "YYYY-MM-DDTHH:mm:ss"
    );

    if (filterType === "today") {
      return momentItemDate.isSame(today, "day");
    } else if (filterType === "yesterday") {
      const yesterday = today.clone().subtract(1, "day");
      return momentItemDate.isSame(yesterday, "day");
    } else if (filterType === "past") {
      const tenDaysAgo = today.clone().subtract(10, "days");
      return momentItemDate.isAfter(tenDaysAgo);
    }
    return true; // Show all items if no filter is applied
  };

  const filterNotesByDateAndImp = (items, filterType) => {
    if (filterType === "isImp") {
      return items?.filter((item) => item?.isImp === true);
    } else {
      return items?.filter(
        (item) =>
          item.text.toLowerCase().includes(searchTerm.toLowerCase()) &&
          filterByDate(
            item,
            getCorrectDateTimeString(item.ntDate, item.ntTime),
            filterType
          )
      );
    }
  };

  const filteredNotes = filterNotesByDateAndImp(notes, filterNoteType);

  const handleNoteFilterClick = (filterType) => {
    setFilterNoteType(filterType);
  };

  return (
    <>
      <div className="pb-20 xs:pb-0">
        <div className="flex items-end flex-wrap justify-end xs:justify-end xs:space-y-0">
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
            handleNoteFilterClick={handleNoteFilterClick}
            handleClearFilter={handleClearFilter}
            filterNoteType={filterNoteType}
          />
        </div>

        {filteredNotes?.length === 0 ? (
          <p className="text-[#ff0000] p-5">No Observations found.</p>
        ) : (
            filteredNotes
              ?.sort((a, b) => {
                const timestampA = getCorrectDateTimeString(a.ntDate, a.ntTime)
                const timestampB = getCorrectDateTimeString(b.ntDate, b.ntTime)
                if (moment(timestampA).isSame(timestampB)) {
                  return a.id > b.id ? -1 : 1 // because seconds are not there so we are comparing SQL id
                } else {
                  return moment(timestampA).isBefore(timestampB) ? 1 : -1
                }
              }
              )
              ?.map((note, i) => (
                <Observation
                  id={i}
                  note={note}
                  setEditObservationOpen={setEditObservationOpen}
                  setObservationToBeEdited={setObservationToBeEdited}
                />
              ))
        )}
      </div>
      <ReuseableBlurModel
        open={editObservationOpen}
        setOpen={setEditObservationOpen}
      >
        <EditObservationForm
          observation={observationToBeEdited}
          setOpen={setEditObservationOpen}
        />
      </ReuseableBlurModel>
    </>
  );
}

export default ObservationBlock;
