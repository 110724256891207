import React, { useState } from "react";
import ReuseableModel from "../Modals/ReuseableModel";
import BpVitalForm from "../Forms/BpVitalForm";
import VitalScoreDisplayCard from "../VitalScoreDisplayCard";
import {
  formatDate,
  formatTime,
  roundToOneDecimal,
} from "../../utils/chartUtils";
import AddEventPlusIconBtn from "../UI/AddEventPlusIconBtn";
import { useUser } from "../../context/UserContext";

function BpAddAndDisplayLiveVital({ bloodPressureVital }) {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  return (
    <>
      <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
        <VitalScoreDisplayCard
          stats={
            bloodPressureVital?.systolic
              ? roundToOneDecimal(bloodPressureVital?.systolic) +
                "-" +
                roundToOneDecimal(bloodPressureVital?.diastolic)
              : null
          }
          bp={true}
        />

        <button
          id='add-vital'
          onClick={() => setOpen(true)}
          disabled={user?.role === "Viewer"}
          className={`${
            user?.role === "Viewer" ? "opacity-50" : ""
          }`}
        >
          <AddEventPlusIconBtn />
        </button>

        <p className="text-white font-semibold text-center text-sm mt-4">
          {bloodPressureVital?.date
            ? `Updated on ${formatDate(
                bloodPressureVital?.date
              )} at ${formatTime(bloodPressureVital?.date)}`
            : null}
        </p>

        <ReuseableModel open={open} setOpen={setOpen}>
          <BpVitalForm setOpen={setOpen} />
        </ReuseableModel>
      </div>
    </>
  );
}

export default BpAddAndDisplayLiveVital;
