import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { forwardRef } from "react";
import { usePatient } from "../../context/PatientContext";
import { manualAddWeightVital } from "../../HTTPS/VitalApis";
import {
  generateTimestampAndOffset,
  getCurrentLocalDate,
  getCurrentLocalTime,
} from "../../utils/chartUtils";
import { useFormik } from "formik";
import { weightVitalValidation } from "../../utils/validations";
import { useToastContext } from "../../context/ToastContext";
import { useUser } from "../../context/UserContext";

function WeightVitalForm({ weigthVital, setOpen }, ref) {
  const { patient } = usePatient();
  const { userDetails } = useUser();
  const queryClient = useQueryClient();
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToastContext();

  const { mutate: handleAddVital, isPending } = useMutation({
    mutationFn: ({ weight, defaultDate, defaultTime }) =>
      patient?.providerUserId
        ? manualAddWeightVital(
            patient?.providerUserId,
            userDetails?.id,
            weight,
            ...generateTimestampAndOffset(defaultDate, defaultTime)
          )
        : Promise.reject(),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(["weigthVital", patient?.id]);
        queryClient.invalidateQueries(["weigthGraph", patient?.id]);
        queryClient.invalidateQueries(["weigthVitalList", patient?.id]);
        
      }, 1000);
      setOpen(false);
      setToastMessage("Vital Added Successfully");
      setOpenSuccessToast(true);
    },
    onError: () => {
      setToastMessage("Failed Adding Vital ");
      setOpenErrorSuccessToast(true);
    },
  });

  const formInitialValues = () => ({
      weight: "",
      defaultDate: getCurrentLocalDate(),
      defaultTime: getCurrentLocalTime(),
    })

  const formik = useFormik({
    initialValues: formInitialValues(),
    validationSchema: weightVitalValidation(),
    onSubmit: (values, { resetForm }) => {
      handleAddVital(values, {
        onSuccess: () => {},
        onError: () => {},
      });
      resetForm({
        values: formInitialValues()
      })
    },
  });

  return (
    <>
      <form className="bg-white z-50 p-5 rounded-2xl">
        <h1 className="text-2xl mb-3">Add data</h1>
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col space-y-1">
            <label htmlFor="dys">
              Weight {weigthVital?.unit && `(${weigthVital?.unit})`}
            </label>
            <input
              type="text"
              {...formik.getFieldProps("weight")} // name, value, onChange, onBlur
              className="border w-72 p-2 rounded-xl"
              placeholder="Weight"
            />
            {formik.touched.weight && formik.errors.weight ? (
              <span className="text-red-500 text-sm">
                {formik.errors.weight}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Date</label>
            <input
              type="date"
              {...formik.getFieldProps("defaultDate")} // name, value, onChange, onBlur
              max={getCurrentLocalDate()}
              placeholder="Date"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultDate && formik.errors.defaultDate ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultDate}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Time</label>
            <input
              type="time"
              {...formik.getFieldProps("defaultTime")} // name, value, onChange, onBlur
              placeholder="Time"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultTime && formik.errors.defaultTime ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultTime}
              </span>
            ) : null}
          </div>

          <div className="flex">
            <button
              type="submit"
              disabled={isPending}
              onClick={formik.handleSubmit}
              className="bg-[#ED7117] rounded-xl p-3 px-7 text-white"
            >
              Save
            </button>
            <button
              onClick={() => setOpen(false)}
              className="rounded-xl p-3 px-7 text-black"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default forwardRef(WeightVitalForm);
