import React, { useEffect, useRef } from 'react';
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePatient } from '../../context/PatientContext';
import { convertLocalDateTimeToUTC, convertLocalDateToUTC, getCorrectDateTimeString, getCurrentLocalDate, getDateFromDate, getMilitaryTimeFromDate } from '../../utils/chartUtils';
import { editVitalEventApi } from '../../HTTPS/ApiService';
import { useToastContext } from '../../context/ToastContext';
import { vitalActivityValidation } from '../../utils/validations';


function EditVitalEventForm({ vitalTypeId = 1, setOpen, activity }) {
  const textareaRef = useRef(null);
  const queryClient = useQueryClient();
  const { patient } = usePatient();
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } = useToastContext();

  const { mutate, isPending } = useMutation({
    mutationFn: async (arg) =>
      await editVitalEventApi({
        ...arg,
        evDate: convertLocalDateToUTC(arg.evDate, arg.evTime),
        evTime: convertLocalDateTimeToUTC(arg.evTime),
      }),
    onSuccess: () => {
      // fetchEvents();
      queryClient.invalidateQueries(["temperatureEvents", patient?.id]);
      setToastMessage("Activity Updated");
      setOpenSuccessToast(true);
      setOpen(false);
    },
    onError: () => {
      setToastMessage("Failed to update activity");
      setOpenErrorSuccessToast(true);
    }
  });

  const formik = useFormik({
    initialValues: {
      "id": activity?.id,
      vitalTypeId,
      "text": activity?.text,
      "evDate": getDateFromDate(getCorrectDateTimeString(activity?.evDate, activity?.evTime)),
      "evTime": getMilitaryTimeFromDate(getCorrectDateTimeString(activity?.evDate, activity?.evTime)),
      recipientId: patient?.id,
      // "type": activity?.type,
      "isImp": activity?.isImp
    },
    validationSchema: vitalActivityValidation(),
    onSubmit: (values) => {
      mutate(values)
    }
  });

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(()=> {
    adjustTextareaHeight();
  })

  return (
    <form className="w-full sm:w-fit mx-auto" onSubmit={formik.handleSubmit}>
      {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
        <p className="text-gray-200 font-semibold px-5 mb-2">
          {Object.values(formik.errors).map((error, index) => (
            <div className="mb-1" key={index}>
              {error}
            </div>
          ))}
        </p>
      )}

      <div className="flex justify-center items-center w-full">
        <div className="px-3 sm:px-5">
          {/* Calendar Input  */}
          <span
            className="mb-2 flex items-center cursor-pointer"
            onClick={() => formik.setFieldValue("isImp", !formik.values.isImp)}
          >
            {formik.values.isImp ? (
              <GradeIcon className="text-[#ED7117] !text-4xl" />
            ) : (
              <GradeOutlinedIcon className="text-[#ED7117] !text-4xl" />
            )}
          </span>
          <div className="flex items-center justify-between sm:justify-start md:space-x-5 flex-wrap">
            <div className="flex flex-col w-full sm:w-auto">
              <label htmlFor="Date" className="text-white">
                Date
              </label>

              <input
                type="date"
                id="Date"
                className="border p-2 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96"
                {...formik.getFieldProps("evDate")} // name, value, onChange, onBlur
                max={getCurrentLocalDate()}
                required
              />
            </div>

            <div className="flex flex-col w-full sm:w-auto">
              <label htmlFor="Time" className="text-white">
                Time
              </label>
              <input
                type="time"
                id="Time"
                className="border p-2 my-2 rounded-xl xs:w-96 md:w-80 lg:w-96"
                {...formik.getFieldProps("evTime")} // name, value, onChange, onBlur
                required
              />
            </div>
          </div>

          <p className=" text-white">Description</p>
          <div className="bg-white border-t border-gray-200 p-2 rounded-xl mb-5">
            <div className="flex justify-between gap-2">
              {/* Input Bar  */}

              <div className="w-full flex items-center space-x-3">
                <div className="w-full">
                  <textarea
                    ref={textareaRef}
                    rows={1}
                    type="text"
                    placeholder="Type Here..."
                    name="message"
                    className="rounded-lg p-2 py-3 w-full max-h-36 min-h-12 resize-none"
                    {...formik.getFieldProps("text")} // name, value, onChange, onBlur
                    maxLength={500}
                    required
                  />
                </div>

                {/* Send Button  */}
                <button
                  className={
                    "bg-[#ED7117] flex items-center justify-center p-2 px-3 text-white font-medium rounded-lg uppercase h-12"
                    + (isPending ? "opacity-75" : "")
                  }
                  type="submit"
                  disabled={isPending}
                >
                  {isPending ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditVitalEventForm;