import React, { forwardRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePatient } from '../../context/PatientContext';
import { useToastContext } from '../../context/ToastContext';
import { tempVitalValidation } from '../../utils/validations';
import { useFormik } from 'formik';
import { editManualTemperatureVital, fetchTemperatureVital } from '../../HTTPS/VitalApis';
import useLiveVitalQuery from '../../hooks/useLiveVitalQuery';
import { convertToLocalDate, convertToLocalTime, generateTimestampAndOffset, getCurrentLocalDate } from '../../utils/chartUtils';
import { useUser } from '../../context/UserContext';

function TempEditVitalForm({ setOpen, entry }, ref) {
  const { patient } = usePatient();
  const { userDetails } = useUser();
  const queryClient = useQueryClient();
  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToastContext();

  const { data: temperatureVital } = useLiveVitalQuery(
    "temperatureVital",
    fetchTemperatureVital
  );

  const { mutate: handleAddVital, isPending } = useMutation({
    mutationFn: async ({ temperature, defaultDate, defaultTime }) =>
      await editManualTemperatureVital(
        patient?.providerUserId,
        userDetails?.id,
        temperature,
        ...generateTimestampAndOffset(defaultDate, defaultTime),
        entry.id
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["TempVitalList", patient?.id]);
      queryClient.invalidateQueries(["temperatureVital", patient?.id]);
      setToastMessage("Updated");
      setOpenSuccessToast(true);
      setOpen(false);
    },
    onError: () => {
      setToastMessage("Update failed");
      setOpenErrorSuccessToast(true);
    },
  });


  const formik = useFormik({
    initialValues: {
      temperature: entry.temperature,
      defaultDate: convertToLocalDate(entry.date),
      defaultTime: convertToLocalTime(entry.date),
    },
    validationSchema: tempVitalValidation(),
    onSubmit: (values) => {
      handleAddVital(values, {
        onSuccess: () => { },
        onError: () => { },
      });
    },
  });


  return (
    <>
      <form className="bg-white z-50 p-5 rounded-2xl">
        <h1 className="text-2xl mb-3">Edit Data</h1>
        <div className="flex flex-col space-y-2">
          {/* TEMP */}
          <div className="flex flex-col space-y-1">
            <label htmlFor="dys">Temperature ({temperatureVital?.unit})</label>
            <input
              type="text"
              {...formik.getFieldProps("temperature")} // name, value, onChange, onBlur
              placeholder="Temperature"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.temperature && formik.errors.temperature ? (
              <span className="text-red-500 text-sm">
                {formik.errors.temperature}
              </span>
            ) : null}
          </div>

          {/* DATE */}
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Date</label>
            <input
              type="date"
              {...formik.getFieldProps("defaultDate")} // name, value, onChange, onBlur
              max={getCurrentLocalDate()}
              placeholder="Date"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultDate && formik.errors.defaultDate ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultDate}
              </span>
            ) : null}
          </div>

          {/* TIME */}
          <div className="flex flex-col space-y-1">
            <label htmlFor="">Time</label>
            <input
              type="time"
              {...formik.getFieldProps("defaultTime")} // name, value, onChange, onBlur
              placeholder="Time"
              className="border w-72 p-2 rounded-xl"
            />
            {formik.touched.defaultTime && formik.errors.defaultTime ? (
              <span className="text-red-500 text-sm">
                {formik.errors.defaultTime}
              </span>
            ) : null}
          </div>

          <div className="flex">
            <button
              type="submit"
              onClick={formik.handleSubmit}
              disabled={isPending}
              className="bg-[#ED7117] rounded-xl p-3 px-7 text-white"
            >
              Save
            </button>
            <button
              onClick={() => setOpen(false)}
              className="rounded-xl p-3 px-7 text-black"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default forwardRef(TempEditVitalForm);