import React, { useState, useRef, useEffect } from "react";
import MessengerService from "../../servies/MessengerService";
import { CachedUserDetails } from "../../utils/UserData";
import { useUser } from "../../context/UserContext";

function MessageSendBar({ isMessage, messageRef, setPreviewMessageCard }) {
  const { userDetails } = useUser();
  // const [modal, setModal] = useState(false)

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  // const toggleModal = () => {
  //   setModal(!modal);
  // };

  const textareaRef = useRef(null);
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [setMessage, message]);

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight; // Current viewport height
      const originalHeight = document.documentElement.clientHeight;

      if (vh < originalHeight - 100) {
        // Assume keyboard is open if viewport height significantly reduces
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const sendMessage = async () => {
    if (message && message.trim() !== "") {
      setError(false);
      try {
        // Simulate sending message (display it immediately)

        const array = [
          userDetails?.fname,
          userDetails?.mname,
          userDetails?.lname,
        ];
        // console.log(array);

        const name = array.join(" ");
        const sentMessage = {
          name: name,
          senderId: CachedUserDetails()?.id,
          content: message,
          profileImage: userDetails?.profileImage,
          time: new Date().toISOString(),
          // Localise me Here
        };
        setMessage("");

        // Display the message immediately
        await setPreviewMessageCard(sentMessage);

        if (sentMessage) {
          messageRef.current.scrollIntoView({ behavior: "smooth" });
        }

        // Simulate network delay before sending the actual message
        // await new Promise((resolve) => setTimeout(resolve, 2000));

        // Uncomment the following lines to send the actual message
        await MessengerService.sendMessage(CachedUserDetails()?.id, message);
        adjustTextareaHeight();
        setPreviewMessageCard(null);
      } catch (error) {
        console.error("Error sending message:", error);
        // Handle the error if needed
      }
    } else {
      setError(true);
    }
  };

  const handleMessageKeyDown = (e) => {
    if (e.key === "Enter") {
      if (window.innerWidth < 768 && !e.shiftKey) {
        e.preventDefault();
        setMessage((prevMessage) => prevMessage + "\n");
      } else if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  return (
    <div style={{ paddingBottom: "0px" }}>
      {/* {modal && (
        <>
          <div className="bg-white w-44 h-max mb-3 rounded-2xl p-5 flex flex-col shadow-xl border border-gray-200 ml-2 justify-center items-start absolute bottom-14">
            <label
              htmlFor="File"
              className="flex items-center gap-2 cursor-pointer mb-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.071 14.242c-.29.29-.75.29-1.03 0a.718.718 0 0 1 0-1.03c2-2 2-5.25 0-7.24-2-1.99-5.25-2-7.24 0-1.99 2-2 5.25 0 7.24.29.29.29.75 0 1.03-.29.29-.75.29-1.03 0-2.57-2.57-2.57-6.75 0-9.31 2.57-2.56 6.75-2.57 9.31 0a6.594 6.594 0 0 1-.01 9.31Z"
                  fill="#262626"
                ></path>
                <path
                  d="M4.929 9.76c.29-.29.75-.29 1.03 0 .29.29.29.75 0 1.03-2 2-2 5.25 0 7.24 2 1.99 5.25 2 7.24 0 1.99-2 2-5.25 0-7.24a.718.718 0 0 1 0-1.03c.29-.29.75-.29 1.03 0 2.57 2.57 2.57 6.75 0 9.31-2.57 2.56-6.75 2.57-9.31 0a6.594 6.594 0 0 1 .01-9.31Z"
                  fill="#262626"
                ></path>
              </svg>
              <input type="file" id="File" className="hidden" />
              <span>Choose File</span>
            </label> */}
      {/* <hr className="my-3.5 w-full" /> */}
      {/* <label htmlFor="camera" className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.242 22.75H6.762c-2.8 0-4.58-1.67-4.74-4.46l-.52-8.25a4.44 4.44 0 0 1 1.21-3.36c.85-.91 2.05-1.43 3.29-1.43.32 0 .63-.19.78-.49l.72-1.43c.59-1.17 2.07-2.08 3.36-2.08h2.29c1.29 0 2.76.91 3.35 2.07l.72 1.46c.15.28.45.47.78.47 1.24 0 2.44.52 3.29 1.43.86.92 1.29 2.11 1.21 3.36l-.52 8.26c-.18 2.83-1.91 4.45-4.74 4.45Zm-6.38-20c-.74 0-1.68.58-2.02 1.25l-.72 1.44c-.42.81-1.23 1.31-2.12 1.31-.84 0-1.62.34-2.2.95-.57.61-.86 1.41-.8 2.24l.52 8.26c.12 2.02 1.21 3.05 3.24 3.05h10.48c2.02 0 3.11-1.03 3.24-3.05l.52-8.26a2.99 2.99 0 0 0-.8-2.24c-.58-.61-1.36-.95-2.2-.95-.89 0-1.7-.5-2.12-1.29L15.152 4c-.33-.66-1.27-1.24-2.01-1.24h-2.28v-.01Z"
                  fill="#262626"
                ></path>
                <path
                  d="M13.5 8.75h-3c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3c.41 0 .75.34.75.75s-.34.75-.75.75ZM12 18.75c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm0-6.5a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5Z"
                  fill="#262626"
                ></path>
              </svg>
              <input type="button" className="hidden" />
              <span className="">Open Camera</span>
            </label>
            <hr className="my-3.5 w-full" />
            <label htmlFor="camera" className="flex items-center gap-2">
              <input type="button" className="hidden" />
              <span className="" onClick={toggleModal}>
                Cancel
              </span>
            </label>
          </div> */}
      {/* </>
      )} */}
      <div className="bg-white border-t border-gray-200 p-2">
        <div className="flex justify-between items-end gap-2">
          {/* Attach file button  */}

          {/* <button
            className="bg-gray-200 rounded-xl p-2 cursor-pointer h-12"
            onClick={toggleModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 12.75H6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12c.41 0 .75.34.75.75s-.34.75-.75.75Z"
                fill="#262626"
              ></path>
              <path
                d="M12 18.75c-.41 0-.75-.34-.75-.75V6c0-.41.34-.75.75-.75s.75.34.75.75v12c0 .41-.34.75-.75.75Z"
                fill="#262626"
              ></path>
            </svg>
          </button> */}
          {/* Input Bar  */}
          <div className="w-full flex space-x-3 bg-white z-50 items-end" style={{
        position: isKeyboardOpen ? "absolute" : "static",
        bottom: isKeyboardOpen ? "0" : "auto",
        width: "100%",
      }}>
            <textarea
              type="text"
              placeholder="Message..."
              name="message"
              value={message} // Use the 'text' state to display the message
              ref={textareaRef}
              onChange={(e) => {
                setMessage(e.target.value);
                adjustTextareaHeight();
              }} // Update 'text' state when the user types
              style={{
                resize: "none",
              }}
              onKeyDown={handleMessageKeyDown}
              rows={1}
              maxLength={500}
              className="border border-gray-500 rounded-lg p-2 w-full max-h-32 min-h-12"
            />
          </div>
          {/* Send Button  */}
          <button
            className="bg-[#ED7117] flex items-center justify-center p-2 px-3 text-white font-medium rounded-lg h-12 uppercase"
            onClick={sendMessage}
          >
            Send
          </button>
          {/* {error && <p className="text-9xl">Message is empty</p>} */}
          {/* end  */}
        </div>
      </div>
    </div>
  );
}

export default MessageSendBar;
