import { date, mixed, number, object, string } from "yup";
import moment from "moment";

import { getCurrentLocalDate } from "./chartUtils";

// WARNING!!! Don't remove this function
export const numberValidation = (unit, max, min) =>
  number()
    .typeError(`${unit} must be a number`)
    .required(`${unit} is required`)
    .lessThan(max, `Must be less than ${max}`)
    .moreThan(min, `Must be greater than ${min}`);

const dateValidation = () =>
  date()
    .required("Date is required")
    .max(getCurrentLocalDate(), "Date cannot be in the future");

    // don't use onlyPast before you change error msg as well
const timeValidation = (dateFieldName = 'defaultDate', onlyPast = true) =>
  string()
    .required('Time is required')
    .test('max-time', 'Time cannot be in the future', function (value) {
      const defaultDate = this.parent[dateFieldName] //prettier-ignore
      const currentDateTime = moment()
      const inputDateTime = moment(
        `${moment(defaultDate).format('YYYY-MM-DD')}T${value}`
      )
      const isBefore = inputDateTime.isSameOrBefore(currentDateTime)
      return onlyPast ? isBefore : !isBefore
    })

export const tempVitalValidation = () => object({
  temperature: numberValidation("Temperature", 107, 96),
  defaultDate: dateValidation(),
  defaultTime: timeValidation()
});


export const bpVitalValidation = () => object({
  systolic: numberValidation("systolic", 140, 100),
  diastolic: numberValidation("diastolic", 100, 60),
  defaultDate: dateValidation(),
  defaultTime: timeValidation()
});

export const weightVitalValidation = () => object({
  weight: numberValidation("weight", 700, 1),
  defaultDate: dateValidation(),
  defaultTime: timeValidation()
});

export const editPatientProfileValidation = object({
  fname: string().required('First name is required'),
  mname: string(),
  lname: string().required('Last name is required'),
  email: string().email('Invalid email'),
  phone: string().matches(/^\(\d{3}\) \d{4} \d{3}$/, 'Invalid phone number'),
});

export const createNoteValidation = () => object().shape({
  text: string()
    .required('Cannot submit an empty note')
    .max(500, 'Observation text must be at most 500 characters long'),
  file: mixed()
    .nullable() // Allow null values (optional)
    // .notRequired() // Field is not required
    .test('fileSize', 'File size should be less than 2 MB', (value) => {
      return !value || value.size <= 2 * 1024 * 1024 // Check size only if file exists or null
    })
    .test('fileType', 'File format should be JPEG or PNG', (value) => {
      return !value || ['image/jpeg', 'image/png'].includes(value.type) // Check type only if file exists or null
    }),
  ntDate: dateValidation(),
  ntTime: timeValidation('ntDate'),
})

export const addActivityValidation = () => object().shape({
  text: string()
    .required('Cannot submit an empty note')
    .max(500, 'Activity text must be at most 500 characters long'),
  type: number().nullable(false).required('Please select a category'),
  evDate: dateValidation(),
  evTime: timeValidation('evDate'),
})

export const createEventFromReminderValidation = object().shape({
  text: string()
    .required('Cannot submit an empty note')
    .max(500, 'Event text must be at most 500 characters long'),
  evDate: dateValidation(),
  evTime: timeValidation('evDate'),
})

export const vitalActivityValidation = () => object().shape({
  text: string()
    .required('Cannot submit an empty note')
    .max(500, 'Vital activity text must be at most 500 characters long'),
  evDate: dateValidation(),
  evTime: timeValidation('evDate'),
})