import { atom } from 'recoil';

export const barChartDataState = atom({
  key: 'barChartDataState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const barChartLoadingState = atom({
  key: 'barChartLoadingState', // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
})