import React from "react";
import { roundToOneDecimal } from "../../utils/chartUtils";

function DashVitalCard({
  liveVital,
  title,
  color = "#ED7117",
  icon,
  scale = 1,
  bp = false,
  text = true,
}) {
  return (
    <div id={title} >
      <div className="flex justify-between items-center">
        <h2 className="text-sm font-bold sm:text-xl text-[#4a4a68]">{title}</h2>

        <div
          className="p-1 rounded-full flex justify-center items-center w-7 h-7 md:h-10 md:w-10"
          style={{ backgroundColor: color }}
        >
          <img
            src={icon}
            alt="icon"
            className="w-6 h-6"
            style={{ scale: scale }} // seems like scale is doing nothings
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center mt-2">
        <span className="text-3xl font-bold text-[#1D3E7D]">
          {
            bp
              ? liveVital?.systolic ? roundToOneDecimal(liveVital?.systolic) + "-" + roundToOneDecimal(liveVital?.diastolic) : "— —"
              : !liveVital?.value ? "—" : roundToOneDecimal(liveVital?.value)
          }
        </span>
        <span className="text-sm font-medium text-[#4a4a68]">
          {text ? liveVital?.unit : null}
        </span>
      </div>
    </div>
  );
}

export default DashVitalCard;
