import { useRecoilValue, useSetRecoilState } from "recoil";
import { checkListState } from "../../../recoil/report/checkListState";
import { useQueries } from "@tanstack/react-query";
import { usePatient } from "../../../context/PatientContext";
import {
  endDateWithTimeSelector,
  startDateWithTimeSelector,
} from "../../../recoil/report/dateRangeState";
import { checkListRaw } from "../../../constant";
import { reportDataState } from "../../../recoil/report/reportDataState";
import { useEffect } from "react";

const Step3 = ({print = false}) => {
  const { patient } = usePatient();
  const checkList = useRecoilValue(checkListState);
  const startDate = useRecoilValue(startDateWithTimeSelector);
  const endDate = useRecoilValue(endDateWithTimeSelector);

  // Flatten the grouped items to get all labels
  const labels = Object.values(checkList).flat();

  const fetchLabelData = async (label) => {
    const api = checkListRaw.find((item) => item.label === label)?.api;
    const res = await api(patient?.id, startDate, endDate);
    return { res, label };
  };

  const setReportData = useSetRecoilState(reportDataState);
  const queryResults = useQueries({
    queries: labels?.map((label) => ({
      queryKey: [label, "Report", patient?.id],
      queryFn: () => fetchLabelData(label),
      enabled: !!patient?.id,
      refetchOnWindowFocus: false,
    })),
  });

  const handleSetReport = () => {
    try {
      if (queryResults.every((result) => result.status === "success")) {
        const reportData = queryResults.map((result) => result);
        setReportData(reportData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (queryResults.every((result) => result.status === "success")) {
      handleSetReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResults]);
  return (
    <>
      {queryResults.map((result, index) => {
        const label = labels[index];
        if (result.isFetching) return <div key={index}>Loading {label}...</div>;
        if (result.isError) return <div key={index}>Error loading {label}</div>;
        const Component = checkListRaw.find(
          (item) => item.label === (result.data.label || label)
        ).component;
        if (!!result?.data?.res) {
          return <Component key={index} data={result.data} className="flex" print={print} />;
        }
        return null;
      })}
    </>
  );
};

export default Step3;
