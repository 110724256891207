import React from "react";
import { useRecoilValue } from "recoil";
import { lineChartDataState, lineChartLoadingState } from "../../recoil/vitalTables/lineChartDataState";
import moment from "moment";

function SleepChartTable({ lastValueDate }) {
  const formattedChartData = useRecoilValue(lineChartDataState);
  const formattedChartLoading = useRecoilValue(lineChartLoadingState);
  const sortedByTimestampDesc = [...formattedChartData].sort((a, b) => b[0] - a[0]);

  if (formattedChartLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {<p>Loading... Please go to the chart tab.</p>}
    </div>;
  }

  if (sortedByTimestampDesc.length === 0) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {!lastValueDate && <p>No Data Available for this Vital</p>}
      {!!lastValueDate && <p>No Recent Readings Captured for this Vital</p>}
    </div>;
  }

  return (
    <div>
      <div className="bg-white py-2.5 px-3 mb-2 rounded-lg flex justify-between items-center w-auto max-w-lg xl:max-w-xl mx-auto gap-x-10 xl:gap-y-0 flex-wrap">
        {sortedByTimestampDesc.map((item, i) => {
          return (
            <div className="w-full flex justify-between flex-wrap items-center border rounded p-2 my-1">
              <div className="flex-1 text-customBlue w-40 text-xs xs:text-base">
                {moment(item[0]).utc().format("DD MMM, YYYY")}
              </div>
              <div className="flex-1 grid grid-cols-4 gap-2 text-xs xs:text-base">
                <span className="font-bold text-customBlue">{item[1]}</span>
                <span className="font-bold text-customBlue">{item[2]}</span>
                <span className="font-bold text-customBlue">{item[3]}</span>
                <span className="font-bold text-customBlue">{item[4]}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SleepChartTable;
