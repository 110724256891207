import React, { useState } from "react";
import ReuseableModel from "../Modals/ReuseableModel";
import WeightVitalForm from "../Forms/WeightVitalForm";
import VitalScoreDisplayCard from "../VitalScoreDisplayCard";
import AddEventPlusIconBtn from "../UI/AddEventPlusIconBtn";
import {
  formatDate,
  formatTime,
  roundToOneDecimal,
} from "../../utils/chartUtils";
import { useUser } from "../../context/UserContext";

function WeightAddAndDisplayLiveVital({ weigthVital }) {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  return (
    <>
      <div className="flex justify-center items-center flex-wrap space-x-5 w-96">
        <VitalScoreDisplayCard stats={roundToOneDecimal(weigthVital?.value)} />

        <button
          id='add-vital'
          onClick={() => setOpen(true)}
          disabled={user?.role === "Viewer"}
          className={`${user?.role === "Viewer" ? "opacity-50" : ""}`}
        >
          <AddEventPlusIconBtn />
        </button>

        <p className="text-white font-semibold text-center text-sm mt-4">
          {weigthVital?.date
            ? `Updated on ${formatDate(weigthVital?.date)} at ${formatTime(
                weigthVital?.date
              )}`
            : null}
        </p>

        <ReuseableModel open={open} setOpen={setOpen}>
          <WeightVitalForm weigthVital={weigthVital} setOpen={setOpen} />
        </ReuseableModel>
      </div>
    </>
  );
}

export default WeightAddAndDisplayLiveVital;
