import React from "react";

import CalendarReminder from "../components/Calendar/CalendarReminder";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import { fetchReminderList } from "../HTTPS/VitalApis";
import useSevenDayQuery from "../hooks/useSevenDayQuery";

const Reminder = () => {

  const { data: reminderList, isLoading } = useSevenDayQuery("ReminderList", fetchReminderList);
  
  // when reminder is loading!
  if (isLoading && !reminderList) {
    return <MainAreaWrapper>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl">
        <div className="mx-auto  p-4 md:p-6 2xl:p-10">
          <p>Loading...</p>
        </div>
      </main>
    </MainAreaWrapper>;
  }

  // when NO reminder found!
  if (!isLoading && (!reminderList || reminderList.length === 0)) {
    return (
      <MainAreaWrapper>
        <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl">
          <div className="mx-auto  p-4 md:p-6 2xl:p-10">
            <p>No reminders found.</p>
          </div>
        </main>
      </MainAreaWrapper>
    );
  }

  return (
    <MainAreaWrapper>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl">
        <div className="mx-auto  p-4 md:p-6 2xl:p-10">
          <div className="mx-auto px-10">
            {/* <!-- Breadcrumb Start --> */}
            <div className="mb-6 flex flex-row items-center justify-between gap-3">
              <h2 className="text-5xl font-bold text-[#1D3E7D]">
                Reminders
              </h2>
            </div>
            {/* <span className="font-bold text-xl">{reminders}</span> */}
          </div>
          <hr />
          <div className="p-5">
            {/* Cards */}
            {
              reminderList?.map((schedule, index) => (
                <div id={index} key={index}>
                  <CalendarReminder schedule={schedule} />
                </div>
              ))
            }
          </div>
        </div>
      </main>
    </MainAreaWrapper >
  );
};

export default Reminder;
