import React from "react";
import { useRecoilValue } from "recoil";

import { rangeBarChartDataState, rangeBarChartLodingState } from "../../recoil/vitalTables/rangeBarChartDataState";

function RangeBarTable({ lastValueDate }) {
  const formattedChartData = useRecoilValue(rangeBarChartDataState);
  const formattedChartLoading = useRecoilValue(rangeBarChartLodingState);
  const currentYear = new Date().getFullYear();
  const sortedByTimestampDesc = [...formattedChartData].sort((a, b) => new Date(`${b.x} ${currentYear}`) - new Date(`${a.x} ${currentYear}`));

  if (formattedChartLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {<p>Loading... Please go to the chart tab.</p>}
    </div>;
  }

  if (sortedByTimestampDesc.length === 0) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {!lastValueDate && <p>No Data Available for this Vital</p>}
      {!!lastValueDate && <p>No Recent Readings Captured for this Vital</p>}
    </div>;
  }

  return (
    <div>
      <div className="bg-white py-2.5 px-3 mb-2 rounded-lg flex justify-between items-center w-72 mx-auto gap-x-10 xl:gap-y-0 flex-wrap">
        {sortedByTimestampDesc.map((item, i) => {
          return (
            <div className="w-full flex justify-between flex-wrap items-center border rounded p-2 my-1">
              <div className="text-customBlue w-40">
                {item?.x}, {item?.z}
              </div>
              <div className="flex-1 grid grid-cols-2 gap-2 border-2">
                {item?.y.map((v) => (
                  <span className="mr-2 font-bold text-customBlue">{v}</span>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RangeBarTable;
